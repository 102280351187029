/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
// import Cookies from 'js-cookie';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Walking from './Walking';
import MyChekhov from './MyChekhov';

const host = 'https://melikhovo.vision';
// const host = 'http://localhost:8080';

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <>
      {value === index && <Box p={3}>{children}</Box>}
    </>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  navBar: {
    justifyContent: 'space-between',
  },
}));

export default function Admin() {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar
        position="static"
      >
        <Toolbar className={classes.navBar}>
          <Typography variant="h6" className={classes.title}>
            Админ-панель
          </Typography>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Прогулка" {...a11yProps(0)} />
            <Tab label="Мой Чехов" {...a11yProps(1)} />
          </Tabs>
          <Button 
            onClick={() => 
              // window.location.href = '/#/logout'
              axios.post(`${host}/logout`)
                .then((res) => {
                  if(res.data === 200) {
                    window.location.href = '/#/login';
                  }
                })
            }
            color="inherit"
          >Выход</Button>
        </Toolbar>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Walking />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MyChekhov />
      </TabPanel>
    </>
  );
}
