import React, { Component, Fragment } from "react";
import axios from 'axios';
import Async from 'react-async';
import './Galery.css';

const host = 'https://melikhovo.vision';
// const front = 'https://melikhovo.vision';
//const host = 'http://localhost:8080';
//const front = 'http://localhost:3000';
const marks = () => axios.post(`${host}/map/marksMap`);
const gallery = () => (
  <Async promiseFn={marks}>
    {({ data }) => {
      if (data)
        return (
          <Fragment>
            {data.data.map((photo, i) => (
              <div key={i} className="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-4 mt-3">
                <img 
                  src={photo.path} 
                  className="galleryPhoto" 
                  alt="" 
                  onLoad={(e) => {if(document.cookie.split('; ').includes(`${photo.path}=border`)) e.target.classList.add('watchedImg');}}
                  onClick={() => {
                    document.cookie = `${photo.path}=border;expires=86400000`;
                    console.log(document.cookie);
                    window.location.href = `#/description?photo=${photo.path}`;
                  }}
                />
              </div>
            ))}
          </Fragment>
        )
      return null
    }}
  </Async>
)

class Galery extends Component {
  componentDidMount() {
    if (document.querySelector('video')) {
      document.querySelector('video').remove();
    }
  }

  render() {
    return (
      <div id="galleryContainer">
        <img
          id="arrow"
          src="./img/arrow.png"
          alt=""
          onClick={() => {
            window.history.back();
          }}
        />
        <div className="row my-3">{gallery()}</div>
      </div>
    );
  }
}
 
export default Galery;
