import React, { Component } from "react";
import axios from 'axios';
import { photos } from './Camera'
import { userEmail } from "./Instruction";
import './End.css';
 
class End extends Component {
    componentDidMount() {
        if (document.querySelector('video')) {
            document.querySelector('video').remove();
          }

        document.body.style.overflowY = 'auto';

        let modal1 = document.getElementById("my_modal1");
        let modal2 = document.getElementById("my_modal2");
        let btnSend = document.getElementById("send");
        let btnEnd = document.getElementById("end");
        let btnNotEnd = document.getElementById("notEnd");
        let span1 = document.getElementsByClassName("close_modal_window")[0];
        let span2 = document.getElementsByClassName("close_modal_window")[1];

	if (!userEmail) {
        document.getElementById('send').disabled = true;
        document.getElementById('send').style.backgroundColor = 'grey';
	}

        btnSend.onclick = () => { 
            modal1.style.display = "block"; 
            axios.post(`https://melikhovo.vision/email`, {photos, userEmail});
            sendBtn.disabled = true;
            document.getElementById('send').style.backgroundColor = 'grey';
        }

        btnEnd.onclick = () => { modal2.style.display = "block"; }

        btnNotEnd.onclick = () => { modal2.style.display = "none"; }

        span1.onclick = () => { modal1.style.display = "none"; }

        span2.onclick = () => { modal2.style.display = "none"; }

        window.onclick = (event) => {
            if (event.target === modal1) {
                modal1.style.display = "none";
            }

            if (event.target === modal2) {
                modal2.style.display = "none";
            }
        }

        const sendBtn = document.getElementById('send');

        // sendBtn.onclick = () => {
            
            
        // }

        // sendBtn.addEventListener('click', (e) => { axios.post(`https://melikhovo.vision/email`, {photos, userEmail}); });
    }

    render() {
        return (
            <div id="endContent">
                <img id="bg" src="../img/lol.png" alt=""/>

                <div id="my_modal1" className="modal">
                    <div className="modal_content">
                        <img
                            src="./img/cancel.png"
                            className="close_modal_window closeModalEnd"
                        />
                        <h3 id="sendCompleted">Фотографии успешно отправлены</h3>
                    </div>
                </div>

                <div id="my_modal2" className="modal">
                    <div className="modal_content">
                        <img
                            src="./img/cancel.png"
                            className="close_modal_window closeModalEnd"
                        />
                        <h2 id="endQuestion">Вы уверены?</h2>

                        <div>
                            <div id="yes" className="my-3">
                                <a aria-current="true" href="#/" className="active questionBtn">Да</a>
                            </div>
                            <div id="no" className="my-3">
                                <a id="notEnd" aria-current="true" href="#/end" className="active questionBtn">Нет</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="outer">
                    <img
                        id="arrowEnd"
                        src="./img/arrow.png"
                        alt=""
                        onClick={() => {
                            window.location.href = '#/map';
                        }}
                    />
                    <h2 id="endHeader">Завершение</h2>
                    <p>Для отправки сделанных фотографий нажмите на  «Отправить». Функция будет доступна, если ранее вами был указан адрес электронной почты.</p>
                    <p>Если при входе в приложение вы выбрали  формат «Сохранять фото на устройство»,  ваши снимки  находятся в галерее устройства.</p>
                    <p>Для завершения использования приложения нажмите на «Завершение».</p>
                    <button id="send" className="endBtn">Отправить</button>
                    <button id="end" className="endBtn">Завершение</button>
                </div>
            </div>
        );
    }
}
 
export default End;
