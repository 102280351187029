/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-indent */
import React from 'react';
import Dropzone from 'react-dropzone';

import MaterialTable from 'material-table';
// eslint-disable-next-line import/no-duplicates
import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Clear from '@material-ui/icons/Clear';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Check from '@material-ui/icons/Check';
import ChevronRight from '@material-ui/icons/ChevronRight';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Search from '@material-ui/icons/Search';
import Refresh from '@material-ui/icons/Refresh';

import axios from 'axios';
import { Typography, TextareaAutosize } from '@material-ui/core';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

const host = 'https://melikhovo.vision';
// const host = 'http://localhost:8080';
class Walking extends React.Component {
  constructor(props) {
    super(props);
    this.onDropPhoto = (files) => {
      this.setState({ photo: files });
    };

    this.onDropExtraImg = (files) => {
      this.setState({ extraImg: files });
    };

    this.onDropAuthorImg = (files) => {
      this.setState({ authorImg: files });
    };

    this.onDropVideo = (files) => {
      this.setState({ video: files });
    };

    this.onDropAudio = (files) => {
      this.setState({ audio: files });
    };

    this.state = {
      photo: [],
      extraImg: [],
      authorImg: [],
      video: [],
      audio: [],
    };

    this.tableRef = React.createRef();
  }

getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

  render() {
    const {
      photo, extraImg, authorImg, video, audio,
    } = this.state;

    return (
      <MaterialTable
        title="Этикетки"
        icons={tableIcons}
        tableRef={this.tableRef}
        columns={[
          {
            title: 'Фотография',
            field: 'photo',
            editComponent: () => (
              <form name="fileinfo" encType="multipart/form-data">
                <Dropzone onFileDialogCancel={() => this.setState({ photo: [] })} onDrop={this.onDropPhoto} multiple={false} accept="image/jpeg, image/png, .2dmap" maxSize={10485760}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="container">
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        {photo.length ? <Typography variant="caption">Файл: {photo[0].name} - {photo[0].size} байт</Typography>
                          : <Typography variant="caption">Перетащите файл сюда или нажмите, чтобы выбрать</Typography>}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </form>
            ),
            render: (rowData) => <Typography variant="caption">{rowData.photo}</Typography>,
          },
          {
            title: 'Подпись',
            field: 'name',
            editComponent: (props) => (
              <TextareaAutosize rows={3} value={props.value} onChange={(e) => { props.onChange(e.target.value); }} />
            ),
          },
          {
            title: 'Доп. фото',
            field: 'extraImg',
            editComponent: () => (
              <Dropzone onFileDialogCancel={() => this.setState({ extraImg: [] })} onDrop={this.onDropExtraImg} multiple={false} accept="image/jpeg, image/png" maxSize={10485760}>
                {({ getRootProps, getInputProps }) => (
                  <section className="container">
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      {extraImg.length ? <Typography variant="caption">Файл: {extraImg[0].name} - {extraImg[0].size} байт</Typography>
                        : <Typography variant="caption">Перетащите файл сюда или нажмите, чтобы выбрать</Typography>}
                    </div>
                  </section>
                )}
              </Dropzone>
            ),
            render: (rowData) => <img alt="" src={`./img/photos/${rowData.extraImg}`} style={{ width: 200 }} />,
          },
          {
            title: 'Подпись к доп. фото',
            field: 'extraImgName',
            editComponent: (props) => (
              <TextareaAutosize rows={3} value={props.value} onChange={(e) => { props.onChange(e.target.value); }} />
            ),
          },
          {
            title: 'Об авторе (фото)',
            field: 'authorImg',
            editComponent: () => (
              <Dropzone onFileDialogCancel={() => this.setState({ authorImg: [] })} onDrop={this.onDropAuthorImg} multiple={false} accept="image/jpeg, image/png" maxSize={10485760}>
                {({ getRootProps, getInputProps }) => (
                  <section className="container">
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      {authorImg.length ? <Typography variant="caption">Файл: {authorImg[0].name} - {authorImg[0].size} байт</Typography>
                        : <Typography variant="caption">Перетащите файл сюда или нажмите, чтобы выбрать</Typography>}
                    </div>
                  </section>
                )}
              </Dropzone>
            ),
            render: (rowData) => <img alt="" src={`./img/photos/${rowData.authorImg}`} style={{ width: 200 }} />,
          },
          {
            title: 'Об авторе',
            field: 'authorText',
            editComponent: (props) => (
              <TextareaAutosize rows={3} value={props.value} onChange={(e) => { props.onChange(e.target.value); }} />
            ),
          },
          {
            title: 'Подробная инф-ия',
            field: 'text',
            editComponent: (props) => (
              <TextareaAutosize rows={3} value={props.value} onChange={(e) => { props.onChange(e.target.value); }} />
            ),
          },
          {
            title: 'Видео',
            field: 'video',
            editComponent: () => (
              <Dropzone onFileDialogCancel={() => this.setState({ video: [] })} onDrop={this.onDropVideo} multiple={false} accept="video/*" maxSize={20971520}>
                {({ getRootProps, getInputProps }) => (
                  <section className="container">
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      {video.length ? <Typography variant="caption">Файл: {video[0].name} - {video[0].size} байт</Typography>
                        : <Typography variant="caption">Перетащите файл сюда или нажмите, чтобы выбрать</Typography>}
                    </div>
                  </section>
                )}
              </Dropzone>
            ),
            render: (rowData) => (
              <video controls style={{ width: 300 }}>
                <source src={`video/${rowData.video}`} />
              </video>
            ),
          },
          {
            title: 'Аудио',
            field: 'audio',
            editComponent: () => (
              <Dropzone onFileDialogCancel={() => this.setState({ audio: [] })} onDrop={this.onDropAudio} multiple={false} accept="audio/*" maxSize={10485760}>
                {({ getRootProps, getInputProps }) => (
                  <section className="container">
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      {audio.length ? <Typography variant="caption">Файл: {audio[0].name} - {audio[0].size} байт</Typography>
                        : <Typography variant="caption">Перетащите файл сюда или нажмите, чтобы выбрать</Typography>}
                    </div>
                  </section>
                )}
              </Dropzone>
            ),
            render: (rowData) => (
              <audio controls src={`./audio/${rowData.audio}`}>
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            ),
          },
        ]}
        data={(query) => new Promise((resolve) => {
          const url = `${host}/trackers/get`;
          axios.post(url, {
            page: query.page + 1,
            pageSize: query.pageSize,
          })
            .then((response) => {
              console.log(response);
              const parsed = [];
              if (!response.data.trackers) {
                return resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
              }

              response.data.trackers.forEach((tracker) => {
                parsed.push({
                  photo: tracker.photo,
                  name: tracker.name,
                  extraImg: tracker.extraImg,
                  extraImgName: tracker.extraImgName,
                  authorImg: tracker.authorImg,
                  authorText: tracker.authorText,
                  text: tracker.desc,
                  video: tracker.video,
                  audio: tracker.audio,
                });
              });

              resolve({
                data: parsed,
                page: query.page,
                totalCount: response.data.rowCount,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        })}
        actions={[
          {
            icon: forwardRef((props, ref) => <Refresh {...props} ref={ref} />),
            tooltip: 'Обновить',
            isFreeAction: true,
            onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
          },
        ]}
        editable={{
          onRowAdd: (newData) => new Promise((resolve) => {
            const {
              photo, extraImg, authorImg, video, audio,
            } = this.state;

            const form = document.forms.namedItem('fileinfo');
            const data = new FormData(form);
            data.append('name', newData.name);
            data.append('photo', photo[0]);
            data.append('extraImg', extraImg[0]);
            data.append('extraImgName', newData.extraImgName);
            data.append('authorImg', authorImg[0]);
            data.append('authorText', newData.authorText);
            data.append('desc', newData.text);
            data.append('video', video[0]);
            data.append('audio', audio[0]);

            axios.post(`${host}/admin/addTracker`, data)
              .then((response) => {
                console.log(response);
                this.setState({
                  photo: [], extraImg: [], authorImg: [], video: [], audio: [],
                });
                resolve();
              });
          }),
          onRowUpdate: (newData, oldData) => new Promise((resolve) => {
            const {
              photo, extraImg, authorImg, video, audio,
            } = this.state;

            const form = document.forms.namedItem('fileinfo');
            const data = new FormData(form);
            data.append('name', newData.name);
            data.append('oldPhotoName', oldData.photo);
            data.append('photo', photo.length > 0 ? photo[0] : '');
            data.append('extraImg', extraImg.length > 0 ? extraImg[0] : '');
            data.append('extraImgName', newData.extraImgName);
            data.append('authorImg', authorImg.length > 0 ? authorImg[0] : '');
            data.append('authorText', newData.authorText);
            data.append('desc', newData.text);
            data.append('video', video.length > 0 ? video[0] : '');
            data.append('audio', audio.length > 0 ? audio[0] : '');

            axios.post(`${host}/admin/editTracker`, data)
              .then((response) => {
                console.log(response);
                this.setState({
                  photo: [], extraImg: [], authorImg: [], video: [], audio: [],
                });
                resolve();
              });
          }),
          onRowDelete: (oldData) => new Promise((resolve) => {
            axios.post(`${host}/admin/delTracker`, {
              photoName: oldData.photo
            })
              .then((response) => {
                console.log(response);
                this.setState({
                  photo: [], extraImg: [], authorImg: [], video: [], audio: [],
                });
                resolve();
              });
          }),
        }}
      />
    );
  }
}

export default Walking;
