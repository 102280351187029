import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Main.css';

const host = 'https://melikhovo.vision';
// const host = 'http://localhost:8080';
class Main extends Component {
  componentDidMount() {
    document.body.style.overflowY = 'auto';
  }

  render() {
    return (
      <div id="mainContainer">
        <img id="trees" src="./img/mainPage/trees.svg" alt="" />

        <h2 className="headerMain">AR-прогулка по чеховской усадьбе</h2>

        <button className="nextBtn" onClick={() => window.location.href = '#/instruction'}>Начать прогулку</button>

        <div className="my-2">
          <div id="ellipse"><img id="chekhov" src="./img/chekhov2.png" alt="" /></div>
        </div>
      </div>
    );
  }
}

export default Main;
