/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-indent */
import React from 'react';
import Dropzone from 'react-dropzone';

import MaterialTable from 'material-table';
// eslint-disable-next-line import/no-duplicates
import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Clear from '@material-ui/icons/Clear';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Check from '@material-ui/icons/Check';
import ChevronRight from '@material-ui/icons/ChevronRight';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Search from '@material-ui/icons/Search';
import Refresh from '@material-ui/icons/Refresh';

import axios from 'axios';
import { Typography, TextareaAutosize } from '@material-ui/core';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

const host = 'https://melikhovo.vision';
// const host = 'http://localhost:8080';

class Walking extends React.Component {
  constructor(props) {
    super(props);
    this.onDrop = (files) => {
      this.setState({ files });
    };

    this.onDropModern = (files) => {
      this.setState({ modernPhotos: files });
    };

    this.state = {
      files: [],
      modernPhotos: [],
    };

    this.tableRef = React.createRef();
  }

getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

  render() {
    const { files, modernPhotos } = this.state;

    return (
      <MaterialTable
        title="Метки"
        icons={tableIcons}
        tableRef={this.tableRef}
        columns={[
          { title: 'Название', field: 'name' },
          {
            title: 'Старинная фотография',
            field: 'photo',
            editComponent: () => (
              <>
                <form name="fileinfo" encType="multipart/form-data">
                  <Dropzone onFileDialogCancel={() => this.setState({ files: [] })} onDrop={this.onDrop} multiple={false} accept="image/jpeg, image/png" maxSize={10485760}>
                    {({ getRootProps, getInputProps }) => (
                      <section className="container">
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input {...getInputProps()} />
                          {files.length ? <Typography variant="caption">Файл: {files[0].name} - {files[0].size} байт</Typography>
                            : <Typography variant="caption">Перетащите файлы сюда или нажмите, чтобы выбрать</Typography>}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </form>
              </>
            ),
            render: (rowData) => <img alt="" src={rowData.photo} style={{ width: 200 }} />,
          },
          {
            title: 'Современная фотография',
            field: 'modernPhotos',
            editComponent: () => (
              <>
                <form name="fileinfo" encType="multipart/form-data">
                  <Dropzone onFileDialogCancel={() => this.setState({ modernPhotos: [] })} onDrop={this.onDropModern} multiple={false} accept="image/jpg, image/jpeg, image/png" maxSize={10485760}>
                    {({ getRootProps, getInputProps }) => (
                      <section className="container">
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input {...getInputProps()} />
                          {modernPhotos.length ? <Typography variant="caption">Файл: {modernPhotos[0].name} - {modernPhotos[0].size} байт</Typography>
                            : <Typography variant="caption">Перетащите файлы сюда или нажмите, чтобы выбрать</Typography>}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </form>
              </>
            ),
            render: (rowData) => <img alt="" src={rowData.modernPhotos} style={{ width: 200 }} />,
          },
	  { title: 'Дата', field: 'date' },
          { title: 'X', field: 'xCoordinate', type: 'numeric' },
          { title: 'Y', field: 'yCoordinate', type: 'numeric' },
          {
            title: 'Атрибуция',
            field: 'attr',
            editComponent: (props) => (
                <TextareaAutosize rows={3} cols={50} value={props.value} onChange={(e) => { props.onChange(e.target.value); }} />
              ),
          },
          {
            title: 'Текст',
            field: 'text',
            editComponent: (props) => (
                <TextareaAutosize rows={3} cols={50} value={props.value} onChange={(e) => { props.onChange(e.target.value); }} />
              ),
          },
        ]}
        data={(query) => new Promise((resolve) => {
          const url = `${host}/map/marks`;
          axios.post(url, {
            page: query.page + 1,
            pageSize: query.pageSize,
          })
            .then((response) => {
              const parsed = [];
              if (!response.data.marks) {
                return resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
              }
              response.data.marks.forEach((mark) => {
                parsed.push({
                  name: mark.name,
		  date: mark.date,
                  photo: mark.path,
                  modernPhotos: mark.modernPath,
                  xCoordinate: mark.latitude,
                  yCoordinate: mark.longitude,
                  attr: mark.attr,
                  text: mark.desc,
                });
              });

              resolve({
                data: parsed,
                page: query.page,
                totalCount: response.data.rowCount,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        })}
        actions={[
          {
            icon: forwardRef((props, ref) => <Refresh {...props} ref={ref} />),
            tooltip: 'Обновить',
            isFreeAction: true,
            onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
          },
        ]}
        editable={{
          onRowAdd: (newData) => new Promise((resolve) => {
            const { files, modernPhotos } = this.state;

            const form = document.forms.namedItem('fileinfo');
            const data = new FormData(form);
            data.append('name', newData.name);
	    data.append('date', newData.date);
            data.append('photo', files[0]);
            data.append('photoName', files[0].name);
            data.append('modernPhotos', modernPhotos[0]);
            data.append('modernPhotoName', modernPhotos[0].name);
            data.append('xCoord', newData.xCoordinate);
            data.append('yCoord', newData.yCoordinate);
            data.append('attr', newData.attr);
            data.append('desc', newData.text);
            data.append('token', this.getCookie('token'));

            axios.post(`${host}/admin/addMark`, data)
              .then((response) => {
                console.log(response);
                this.setState({ files: [] });
                this.setState({ modernPhotos: [] });
                resolve();
              });
          }),
          onRowUpdate: (newData, oldData) => new Promise((resolve) => {
            const { files, modernPhotos } = this.state;

            const form = document.forms.namedItem('fileinfo');
            const data = new FormData(form);
            data.append('name', newData.name);
            data.append('oldName', oldData.name);
	          data.append('date', newData.date);
            data.append('photo', files.length > 0 ? files[0] : '');
            data.append('photoName', files.length > 0 ? files[0].name : '');
            data.append('modernPhotos', modernPhotos.length > 0 ? modernPhotos[0] : '');
            data.append('modernPhotoName', modernPhotos.length > 0 ? modernPhotos[0].name : '');
            data.append('xCoord', newData.xCoordinate);
            data.append('yCoord', newData.yCoordinate);
            data.append('attr', newData.attr);
            data.append('desc', newData.text);
	          data.append('oldXCoord', oldData.xCoordinate);
            data.append('oldYCoord', oldData.yCoordinate);
	          data.append('oldDesc', oldData.text);

            axios.post(`${host}/admin/changeMark`, data)
              .then((response) => {
                console.log(response);
                this.setState({ files: [] });
                this.setState({ modernPhotos: [] });
                resolve();
              });
          }),
          onRowDelete: (oldData) => new Promise((resolve) => {
            axios.post(`${host}/admin/delMark`, {
              name: oldData.name,
	      xCoord: oldData.xCoordinate,
	      yCoord: oldData.yCoordinate,
	      desc: oldData.text
            })
              .then((response) => {
                console.log(response);
                this.setState({ files: [] });
                this.setState({ modernPhotos: [] });
                resolve();
              });
          }),
        }}
      />
    );
  }
}

export default Walking;
