import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { photoCamera } from './MarkDescription';
import { Button } from "./components/AuthForm";
import { userEmail } from "./Instruction";
import { VKShareButton } from 'react-share';
import axios from 'axios';
import Gesture from 'rc-gesture';
import Hammer from 'hammerjs';
import './Camera.css';

export let photos = [];
let newPhoto;
const host = 'https://melikhovo.vision';
const modernPhoto = () => axios.post(`${host}/modernPath`, { photoCamera });
let opacity;
let margin;
let countPhotoSetSize = 0;

class Camera extends Component {
    constructor (props) {
    	super(props);
    	this.state = {
        	valueSize: (window.innerWidth > window.innerHeight) ? 0.5*window.innerWidth : 0.5*window.innerHeight,
            minSize: (window.innerWidth > window.innerHeight) ? 0.5*window.innerWidth : 0.5*window.innerHeight,
            maxSize: (window.innerWidth > window.innerHeight) ? 1.5*window.innerWidth : 1.5*window.innerHeight,
            valueOpacity: 0.65,
            minOpacity: 0.3,
            maxOpacity: 1,
            shareUrl: "",
        };

        this.handleChangeSize = this.handleChangeSize.bind(this);
        this.handleChangeOpacity = this.handleChangeOpacity.bind(this);
    }

    handleChangeSize(event) {
        this.setState({valueSize: event.target.value});
        let img = document.getElementById("photo");

        if (window.innerWidth < window.innerHeight) {
            img.height = this.state.valueSize;
        } else {
            img.width = this.state.valueSize;
        }
    }

    onPinchGestureEvent = event => {
        console.log(event.nativeEvent.scale);
    }

    handleChangeOpacity(event) {
        this.setState({valueOpacity: event.target.value});
        let img = document.getElementById("photo");
        img.style.opacity = this.state.valueOpacity;
    }

    updateDimensions = () => {
    	const photo = document.getElementById('photo');
        const video = document.querySelector('video');

        try {
            if (video) {
                const btnPanel = document.getElementById('btnPanel');
                const inputRange = document.getElementById('inputRange');
                const inputOpacity = document.getElementById('inputOpacity');
                const arrowCamera = document.getElementById('arrowCamera');
                const questionBtn = document.getElementById('questionMarkCamera')
                const rotationPhone = document.getElementById('rotationPhone');
                const rotationPhoneImg = document.getElementById('rotationPhoneImg');

                window.onresize =  () => {
                    if (photo) {
                        if (photo.width > photo.height) {
                            if (window.innerWidth < window.innerHeight) {
                                rotationPhone.style.display = 'block';
                                rotationPhone.style.width = window.innerWidth + 'px';
                                rotationPhone.style.height = window.innerHeight + 'px';
                                rotationPhoneImg.style.marginTop = '50%';
                                photo.style.transform = 'rotate(90deg)';
                                photo.style.marginLeft = (window.innerHeight - photo.width > 0) ?
                                    (this.state.minSize - this.state.valueSize)/4 + 'px' :
                                    this.state.minSize - this.state.valueSize/1.5 + 'px';

                                btnPanel.classList.add('resize');
                                inputRange.classList.add('resizeInputRange');
                                inputOpacity.classList.add('resizeInputOpacity');
                                arrowCamera.classList.add('resizeArrow');
                                questionBtn.classList.add('resizeQuestion');
                            } else {
                                if (btnPanel.classList.contains('resize')) {
                                    photo.style.marginLeft = `auto`;
                                    rotationPhone.style.display = 'none';
                                    photo.style.transform = 'rotate(0deg)';
                                    btnPanel.classList.remove('resize');
                                    inputRange.classList.remove('resizeInputRange');
                                    inputOpacity.classList.remove('resizeInputOpacity');
                                    arrowCamera.classList.remove('resizeArrow');
                                    questionBtn.classList.remove('resizeQuestion');
                                }

                                btnPanel.style.top = '84%';
                                inputRange.style.top = '84%';
                                inputOpacity.style.top = '84%';
                                questionBtn.style.top = '84%';
                            }
                        } else {
                            if (window.innerWidth > window.innerHeight) {
                                photo.style.transform = 'rotate(-90deg)';
                                rotationPhone.style.display = 'block';
                                rotationPhoneImg.style.marginTop = '10%';
                                rotationPhone.style.width = window.innerWidth + 'px';
                                rotationPhone.style.height = window.innerHeight + 'px';
                                btnPanel.classList.add('resizeHeightPhoto');
                                inputRange.classList.add('resizeInputRangeHeightPhoto');
                                inputOpacity.classList.add('resizeInputOpacityHeightPhoto');
                                arrowCamera.classList.add('resizeArrowHeight');
                                questionBtn.classList.add('resizeQuestionHeight');
                            } else {
                                if (btnPanel.classList.contains('resizeHeightPhoto')) {
                                    rotationPhone.style.display = 'none';
                                    photo.style.transform = 'rotate(0deg)';
                                    btnPanel.classList.remove('resizeHeightPhoto');
                                    inputRange.classList.remove('resizeInputRangeHeightPhoto');
                                    inputOpacity.classList.remove('resizeInputOpacityHeightPhoto');
                                    arrowCamera.classList.remove('resizeArrowHeight');
                                    questionBtn.classList.remove('resizeQuestionHeight');
                                }
                            }
                        }

                        margin =  getComputedStyle(photo, '').marginLeft;
                    }
                };

                if (countPhotoSetSize == 0) {
                    if (photo.width > photo.height) {
                        photo.width = this.state.valueSize;
                    } else {
                        photo.height = this.state.valueSize;
                    }

                    countPhotoSetSize++;
                }

                document.getElementById('snap').width = 0.5*window.innerWidth;
            }
        } catch(e) {}
    }

    componentDidMount() {
        const photo = document.getElementById('photo');
	if (photo.width > photo.height) {
                        photo.width = this.state.valueSize;
                    } else {
                        photo.height = this.state.valueSize;
                    }


        window.addEventListener('resize', this.updateDimensions);

        document.body.style.overflowY = 'hidden';
        document.body.style.overflowX = 'hidden';
        photo.style.opacity = 0.7;

        const hammertime = new Hammer(photo);
        hammertime.get('pinch').set({ enable: true });
        hammertime.on('pinch', ev => {
            this.setState({valueSize: document.getElementById('size').value * ev.scale});
            let img = document.getElementById("photo");
            if (this.state.valueSize > this.state.minSize && this.state.valueSize < this.state.maxSize) {
                if (window.innerWidth < window.innerHeight) {
                    img.height = this.state.valueSize;
                } else {
                    img.width = this.state.valueSize;
                }
            }
        });

        const modal1 = document.getElementById("modalPhotoHide");
        const span1 = document.getElementsByClassName("close_modal_window")[0];
        const questionBtn = document.getElementById('questionMarkCamera');

        questionBtn.onclick = () => { modal1.style.display = "block"; }

        span1.onclick = () => { modal1.style.display = "none"; }

        window.onclick = (event) => {
            if (event.target === modal1) {
                modal1.style.display = "none";
            }
        }

        let i = 0;

        let modal = document.getElementById("my_modal");
        let btn = document.getElementById("takePhoto");
        let span = document.getElementsByClassName("close_modalCamera_window")[0];

        btn.onclick = () => { modal.style.display = "block"; }

        span.onclick = () => { modal.style.display = "none"; }

        window.onclick = (event) => {
            if (event.target === modal) {
                modal.style.display = "none";
            }
        }

        let image = document.querySelector('#snap');
        let take_photo_btn = document.querySelector('#takePhoto');
        let download_photo_btn = document.querySelector('#download-photo');

        download_photo_btn.style.display = 'none';

        function takeSnapshot(video) {
            let hidden_canvas = document.querySelector('canvas');
            let context = hidden_canvas.getContext('2d');
            let img = document.getElementById('photo');

	    let widthVideo = video.offsetWidth;
            let heightVideo = video.offsetHeight;
	    let width = window.innerWidth;
	    let height = window.innerHeight;

            if (width && height) {
                hidden_canvas.width = width;
                hidden_canvas.height = height;
                let imgWidth = img.width;
                let imgHeight = img.height;
		//context.drawImage(video, (window.innerWidth-video.offsetWidth), 0);
                //context.drawImage(video, window.innerWidth-video.offsetWidth, (video.offsetHeight-window.innerHeight)/2, width, height, 0, 0, widthVideo, heightVideo);
		if (window.innerWidth > window.innerHeight) {
			context.drawImage(video, 0, (window.innerHeight-video.offsetHeight)/2, widthVideo, heightVideo);
		} else {
			context.drawImage(video, (window.innerWidth - video.offsetWidth)/2, 0, widthVideo, heightVideo);
		}
                context.globalAlpha = img.style.opacity;
                context.drawImage(img, img.getBoundingClientRect().left, img.getBoundingClientRect().top, imgWidth, imgHeight);

                return hidden_canvas.toDataURL('image/png');
            }
        }

        take_photo_btn.addEventListener("click", (e) => {
            e.preventDefault();
            let video = document.querySelector('video');
            let snap = takeSnapshot(video);

            image.setAttribute('src', snap);
            image.classList.add("visible");

            download_photo_btn.href = snap;

            // document.querySelector('.socialMediaShareButton').setAttribute('image', snap);
            // document.querySelector('.socialMediaShareButton').setAttribute('href', `https://vk.com/share.php?image=${snap}`);
            
            // console.log(document.querySelector('.SocialMediaShareButton').image);
            // console.log(document.querySelector('.SocialMediaShareButton'));

            if (userEmail) {
                photos[i] = {
                    filename: `${i}.png`,
                    path: snap
                };
                i++;
            } else {
                function dataURIToBlob(dataURI, callback) {
                    let binStr = atob(dataURI.split(',')[1]),
                        len = binStr.length,
                        arr = new Uint8Array(len);

                    for (let i = 0; i < len; i++) {
                        arr[i] = binStr.charCodeAt(i);
                    }

                    callback(new Blob([arr]));
                }

                let callback = (blob) => {
                    let a = document.createElement('a');
                    a.download = 'photo.png';
                    a.innerHTML = 'download';
                    a.href = URL.createObjectURL(blob);
                    a.click();

                    a.onclick = () => {
                    requestAnimationFrame(() => {
                        URL.revokeObjectURL(a.href);
                        });
                        a.removeAttribute('href')
                    };
                };

                dataURIToBlob(snap, callback);
            }
        });
    }

    render() {
        return (
            <div>
                <div id="modalPhotoHide" className="modal">
                    <div className="modal_content_Camera">
                        <img
                            src="./img/cancel.png"
                            className="close_modal_window"
                            id="closeModalCamera"
                        />
                        <br/>
                        <img
                            src={modernPhoto}
                            alt=""
                            id="newImg"
                        />
                        <img
                            src={photoCamera}
                            alt=""
                            id="oldImg"
                        />
                    </div>
                </div>
                <div id="rotationPhone">
                    <img src="./img/rotation-phone.png" id="rotationPhoneImg" />
                    <br/>
                    <h4 className="mt-4">Переверните ваше устройство</h4>
                </div>
                {/* <Gesture
		            enablePinch
                    onPinch={(el) => {
                        this.setState({valueSize: document.getElementById('size').value * el.scale/2});
                        let img = document.getElementById("photo");
			            if (this.state.valueSize > this.state.minSize && this.state.valueSize < this.state.maxSize) {
                            if (window.innerWidth < window.innerHeight) {
                                img.height = this.state.valueSize;
                            } else {
                                img.width = this.state.valueSize;
                            }
                        }
                    }}
                > */}
                    <img src={photoCamera} id="photo" alt=""/>
                    {/* <img src="./img/marksPhoto/14.png" id="photo" alt=""/> */}
                {/* </Gesture> */}

                <div id="inputRange">
                    <input
                        type="range"
                        min={this.state.minSize}
                        max={this.state.maxSize}
                        id="size"
                        value={this.state.valueSize}
                        onChange={this.handleChangeSize}
                    />
                    <br/>
                    Размер
                </div>

                <div id="inputOpacity">
                    <input
                        type="range"
                        min={this.state.minOpacity}
                        max={this.state.maxOpacity}
                        step="0.01"
                        value={this.state.valueOpacity}
                        id="photoOpacity"
                        onChange={this.handleChangeOpacity}
                    />
                    <br/>
                    Прозрачность
                </div>
                <div id="my_modal" className="modalCamera">
                    <div className="modalCamera_content">
                        <span className="close_modalCamera_window">×</span>
                        <a href="#" id="download-photo" download="photo.png" title="Save Photo">kek</a>
                        <img id="snap" alt=""/>
                        <canvas></canvas>
                        <VKShareButton
                            className="socialMediaShareButton"
                            url="https://melikhovo.vision"
                            image="https://vk.com/share.php?image=https://melikhovo.vision/img/chekhov.png"
                        >
                        {/* <a className="socialMediaShareButton" href="https://vk.com/share.php?image='https://melikhovo.vision/img/chekhov.png'" target="_blank"> */}
                            <img src="./img/social.png"
                               id="socialBtn"
                                // onClick={(e) => {
                                //     const imageURl = e.target.getAttribute('image');
                                //     axios.post(`https://vk.com/share.php?image='http://localhost:3000/img/chekhov.png'`)
                                // }}
                            />
                            {/* </a> */}
                        </VKShareButton>
                    </div>
                </div>
                <img
                    id="arrowCamera"
                    src="./img/arrowWhite.png"
                    alt=""
                    onClick={() => {
                        document.getElementById('arjs-video').remove();
                        window.history.back();
                    }}
                />
                <div id="btnPanel">
                    <img id="takePhoto" src="./img/cameraWhite.png" alt="" />
                </div>
                <div
                    id="questionMarkCamera"
                    className="findAngleBlockCamera"
                    onClick={async () => {
                        newPhoto = await axios.post(`${host}/modernPath`, { photoCamera });
                        document.getElementById('newImg').setAttribute('src', newPhoto.data[0].modernPath);

                        const photo = document.getElementById('oldImg');
                        photo.classList.add('photoHide');

                        setInterval(() => {
                            photo.classList.add('photoHide');
                        }, 5000);
                    }}
                >
                    <div className="findAngleBorderCamera">
                        Найти<br/>ракурс
                    </div>
                </div>
                <a-scene
                    cursor='rayOrigin: mouse; fuse: true; fuseTimeout: 0;'
                    vr-mode-ui="enabled: false"
                    embedded
                    arjs='sourceType: webcam; debugUIEnabled: false; sourceWidth:1280; sourceHeight:960; displayWidth: 1280; displayHeight: 960;'
                ></a-scene>
            </div>
        );
    }
}
 
export default Camera;

