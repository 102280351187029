import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { withCookies } from 'react-cookie';

const host = 'https://melikhovo.vision';

export default function withAuth(ComponentToProtect) {
  return withCookies(class extends Component {
    constructor(props) {
      super();
      this.state = {
        loading: true,
        redirect: false,
      };

    }

    componentDidMount() {
        const { cookies } = this.props;

      axios.post(`${host}/checkToken`, {
          token: cookies.get('token')
      })
        .then((res) => {
          if (res.status === 200) {
            this.setState({ loading: false });
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({ loading: false, redirect: true });
        });
    }

    render() {
      const { loading, redirect } = this.state;
      if (loading) {
        return null;
      }
      if (redirect) {
        return <Redirect to="/login" />;
      }
      return <ComponentToProtect {...this.props} />;
    }
    });
}
