import React, { Component, Suspense, lazy } from 'react';
import Preloader from './Preloader';
// Импортируем основной компонент
const MarkDescription = lazy(() => import('./MarkDescription'));

export default class Cover extends Component{
    render(){
        return(
            <Suspense fallback={<Preloader/>}>
                <MarkDescription />
            </Suspense>
        );
    }
}