import React, { Component } from "react";
import './Instruction.css';

export let userEmail;
class Instruction extends Component {
  getEmail() {
    userEmail = document.getElementById('email').value;
  }

  checkBoxEmail() {
    let checkEmail = document.getElementById("checkEmail").checked;
    let inputEmail = document.getElementById("email");
    if (checkEmail) {
      inputEmail.disabled = true;
    } else {
      inputEmail.disabled = false;
    }
  }

  render() {
    return (
      <div className="divContent">
        <div>
          <div id="ellipseInstruction"><img src="./img/hat.png" alt="" /></div>

          <h2 id="headerInstruct" className="paddingTextInstr">Дорогие друзья!</h2>
          <br/>
          <p className="paddingTextInstr">
            <strong>
              Прогулка по чеховской усадьбе с дополненной реальностью позволит вам увидеть Мелихово таким, каким его видели хозяева и их гости в конце 19 века.
            </strong>
          </p>
        </div>
        <p>
          За семь лет «мелиховского сидения» было сделано чуть более двух десятков любительских снимков. Фотографии, отснятые разными людьми в разное время, объединяет  одно – в фокусе  внимания их авторов не безлюдные усадебные виды, а портреты и сюжеты, выхваченные из повседневной жизни писателя и его семьи. Вероятно поэтому и сам Чехов, каким вы его здесь увидите, далек от привычного образа школьного классика в пенсне.
        </p>
        <p>
          Приятной прогулки и интересных открытий!
        </p>
        <br />
        <input type="checkbox" id="checkEmail" name="checkEmail" onClick={this.checkBoxEmail} />
        <label className="pl-2">Сохранять фото на устройство</label>
        <br/>
        <p>
          Укажите свой e-mail для отправки сделанных фотографий на почту
        </p>

        <input type="email" name="email" id="email" placeholder="E-mail" onChange={this.getEmail}/>

        <button className="nextBtn" onClick={() => window.location.href = '#/markers'}>Продолжить</button>
      </div>
    );
  }
}
 
export default Instruction;
