import React, { useState } from 'react';
import './App.css';
import {
  Route,
  HashRouter,
  Link,
} from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import Main from './Main';
import Instruction from './Instruction';
import Markers from './Markers';
import YaMap from './Map';
import Camera from './Camera';
import End from './End';
import Admin from './Admin';
import Login from './Login';
import Galery from './Galery';
import MarkDescription from './CoverMarkDescription';
import { AuthContext } from './context/auth';
// import PrivateRoute from './PrivateRoute';
import withAuth from './withAuth';


function App() {
  const [authTokens, setAuthTokens] = useState();

  const setTokens = (data) => {
    localStorage.setItem('tokens', JSON.stringify(data));
    setAuthTokens(data);
  };

  return (
    <div>
      <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
        <CookiesProvider>
          <HashRouter>
            <div>
              <div className="content">
                <Route exact path="/" component={Main} />
                <Route path="/instruction" component={Instruction} />
                <Route path="/markers" component={Markers} />
                <Route path="/map" component={YaMap} />
                <Route path="/camera" component={Camera} />
                <Route path="/end" component={End} />
                <Route path="/login" component={Login} />
                <Route path="/admin" component={withAuth(Admin)} />
                <Route path="/galery" component={Galery} />
                <Route path="/description" component={MarkDescription} />
              </div>
            </div>
          </HashRouter>
        </CookiesProvider>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
