import React, { Fragment } from 'react';
import { YMaps, Map, Placemark, Clusterer } from 'react-yandex-maps';
import './Map.css';
import axios from 'axios';
import Async from 'react-async';
import userEmail from './Instruction';

const host = 'https://melikhovo.vision';
// const host = 'http://localhost:8080';
let place;
let prevCount = 0;
let nextCount = 1;

const mapState = { center: [55.11537204, 37.64819057], zoom: 17 };

class MapBasics extends React.Component {
    constructor(props) {
        super();

        this.state = {
            data: '',
            width: window.innerWidth,
            height: window.innerHeight,
        };
      }

    state = { width: window.innerWidth, height: window.innerHeight };

    loadMarks = () => axios.post(`${host}/map/marksMap`);

    getSrcPhoto(path) {
        setTimeout(() => {
            prevCount++;
            window.location.href = `#/description?photo=${path}`;
        }, 1000);
    }

    placemarks = () => (
        <Async promiseFn={this.loadMarks}>
            {({ data, err, isLoading }) => {
                if (data) {
                    {console.log(prevCount, nextCount)}
                    {nextCount++}
                    // {console.log(data)}
                    return (
                        <Fragment>
                            <Clusterer
                                options={{
                                    preset: 'islands#brownClusterIcons',
                                    groupByCoordinates: false,
                                }}

                                modules={['clusterer.addon.balloon']}
                            >
                                {data.data.map((mark, i) => (
                                    <Placemark
                                        key={i}
                                        geometry={[mark.latitude, mark.longitude]}
                                        properties={{
                                            // hintContent: mark.name,
                                            clusterCaption: `<a onClick="window.location.href = '#/description?photo=${mark.path}'"><img src="./img/mark.svg" alt="" style="width: 22px; margin-right: 8px;" />${mark.name}</a>`,
                                        }}
                                        options={{
                                            iconLayout: 'default#image',
                                            iconImageHref: './img/mark.svg',
                                            iconImageSize:[30, 30],
                                            iconImageOffset:[-24, -24],
                                            iconContentOffset:[0, 0]
                                        }}
                                        modules={['geoObject.addon.balloon']}
                                        onClick={() => {
                                            prevCount++;
                                            window.removeEventListener('resize', this.updateDimensions);
                                            this.getSrcPhoto(mark.path);
                                        }}
                                    />
                                ))}
                            </Clusterer>
                        </Fragment>
                    )
                }
                return null
            }}
        </Async>
    )

    componentDidMount() {
        this.setState({
            data: this.placemarks()
        });

        window.addEventListener('resize', this.updateDimensions);

        document.body.style.overflow = 'none';

        if (document.querySelector('video')) {
            document.querySelector('video').remove();
        }
    }

    updateDimensions = () => {
        if (this.state.width !== window.innerWidth) {
            this.setState({width: window.innerWidth});
            this.setState({height: window.innerHeight});
        }
    }

    onLoadMap = async (inst) => {
        const isCheck = new Array(23).fill(0);
        let position = [];
        (await this.loadMarks()).data.forEach(el => {
            position.push([el.latitude, el.longitude]);
        });

        window.ymaps.geolocation.get(
            { provider: 'browser', mapStateAutoApply: false },
            ).then((result) => {
                result.geoObjects.options.set('preset', 'islands#redCircleIcon');
                inst.geoObjects.add(result.geoObjects);
                place = result;
		        inst.geoObjects.remove(result.geoObjects);

            });

        setTimeout(() => {
            navigator.geolocation.watchPosition(
                (position) => {
                    try {
                        inst.geoObjects.remove(place.geoObjects);
                        place.geoObjects.position[0] = position.coords.latitude;
                        place.geoObjects.position[1] = position.coords.longitude;
                        inst.geoObjects.add(place.geoObjects);
                        const indexPosition = position.indexOf(place.geoObjects.position);
                        if (position.includes(place.geoObjects.position) && isCheck[indexPosition] === 0) {
                            window.navigator.vibrate([1000,1000,1000]);
                            setTimeout(() => {
                                isCheck[indexPosition] = 0;
                            }, 300000);
                        }
                    }catch(e) { }

                    






                    // if (condition) {
                        // console.log(place);
                    // }
                    // inst.geoObjects.remove(place.geoObjects);
                    // place.geoObjects.geometry[0] = position.coords.latitude;
                    // place.geoObjects.geometry[1] = position.coords.longitude;
                    // place.geoObjects.position[0] = position.coords.latitude;
                    // place.geoObjects.position[1] = position.coords.longitude;
                    // inst.geoObjects.add(place.geoObjects);
                    // console.log(place.geoObjects);
                    // place.geoObjects.geometry = [position.coords.latitude,position.coords.longitude];
                    // console.log([place.geoObjects.position[0], place.geoObjects.position[1]]);

                    // console.log(inst.geoObjects);

                    // window.navigator.vibrate([1000,2000,1000]);
                    /*place.geoObjects.geometry.setCoordinates([position.coords.latitude,position.coords.longitude]);*/
                    // place.geoObjects.geometry.setCoordinates([position.coords.latitude,position.coords.longitude]);
                    // console.log(place.geoObjects.geometry);
                    /*place.geoObjects.position[0] = position.coords.latitude;
                    place.geoObjects.position[1] = position.coords.longitude;*/
                        /*inst.geoObjects.geometry.setCoordinates(e.get("coordPosition"));*/
                }
            );
        }, 100);
    }

    render() {
        const { width, height } = this.state;

        return (
            <YMaps>
                <Map
                    modules={['geolocation', 'geocode']}
                    state={mapState}
                    width={width}
                    height={height}
                    instanceRef={(map) => this.onLoadMap(map)}
                >
                    {this.state.data}
                </Map>

                <div id="finishDiv">
                    <img
                        id="finishBtn"
                        src="./img/cancel.png"
                        onClick={() => window.location.href = '#/end'}
                    />
                </div>

                <div id="galeryBlock"
                    onClick={() => {
                        window.location.href = '#/galery'
                    }}
                >
                    <img id="galeryIcon" src="./img/galery.png" alt="" />
                    <br />
                    <p>Галерея</p>
                </div>
                <div id="instructionBlock"
                    onClick={() => {
                        window.location.href = '#/markers'
                    }}
                >
                    <img id="instructionIcon" src="./img/instruction.png" alt="" />
                    <br />
                    <p>Инструкция</p>
                </div>
            </YMaps>
        );
    }
}

export default MapBasics;
