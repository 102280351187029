import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import {
 Card, Form, Input, Button, Error 
} from './components/AuthForm';
// import { useAuth } from "./context/auth";

const host = 'https://melikhovo.vision';
// const host = 'http://localhost:8080';

function Login(props) {
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [cookies, setCookie] = useCookies(['token']);

  let referer;


  function postLogin() {
    axios.post(`${host}/login`, {
      username: userName,
      password,
    })
      .then((res) => {
        if (res.status === 200) {
          setCookie('token', res.data.token, { path: '/' });
          props.history.push('/admin');
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
        alert('Error logging in please try again');
      });
  }

  return (
    <Card>
      <Form>
        <br />
        <br />
        <Input
          type="username"
          value={userName}
          onChange={(e) => {
            setUserName(e.target.value);
          }}
          placeholder="login"
        />
        <Input
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          placeholder="password"
        />
        <Button onClick={postLogin}>Вход</Button>
      </Form>
      { isError && <Error>Введенные логин или пароль неверны!</Error> }
      <Link to="/">Назад</Link>
      {/* <Link to="/signup">Don't have an account?</Link> */}

    </Card>
  );
}

export default Login;
