import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Async from 'react-async';
import './MarkDescription.css';
// import { histPhoto } from './Galery';
// import { photo } from './Map';

function getUrlVars() {
  const vars = {};
  const parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    vars[key] = value;
  });
  return vars;
}

export let photoCamera;
// export let modernPhotoCamera;

const host = 'https://melikhovo.vision';
// const host = 'http://localhost:8080';
const mark = () => axios.post(`${host}/markDesc`, { photoMark: getUrlVars().photo });

const description = () => (
  <Async promiseFn={mark}>
    {({ data, error, isLoading }) => {
      if (error) return `Something went wrong: ${error.message}`;
      if (data) {
        return (
          <>
            <div>
              <img
                id="markDescClose"
                src="./img/closeWhite.png"
                alt=""
                onClick={() => {
                  window.location.href = '#/map';
                }}
              />
              <img
                id="arrowMarkDesc"
                src="./img/arrowWhite.png"
                alt=""
                onClick={() => {
                  window.history.back();
                }}
              />
              <img className="imgDesc" src={data.data.path} alt="" />
              <div className="my-2 markDescBlock">
                <h3>{data.data.name}</h3>
                <p>{data.data.date}</p>
                <hr id="markDescHr" />
                <br />
                <br />
                <p>{data.data.attr}</p>

                <button
                  id="goToCamera"
                  className="nextBtn"
                  onClick={() => {
                    photoCamera = getUrlVars().photo;
                    document.cookie = `${data.data.path}=border;expires=86400000`;
                    window.location.href = '#/camera';
                  }}
                >
                  Назад в прошлое
                </button>
                <br />
                <img id="markDescArrows" src="./img/arrowsDown.png" alt="" />

                <p className="my-2">{data.data.desc}</p>
              </div>
            </div>
          </>
        );
      }
      return null;
    }}
  </Async>
);

class MarkDescription extends Component {
  componentDidMount() {
    if (document.querySelector('video')) {
      document.querySelector('video').remove();
    }
  }

  render() {
    return (
      <div id="markDescContainer">
        {description()}
      </div>
    );
  }
}

export default MarkDescription;
