/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';
import './Markers.css';

// eslint-disable-next-line react/prefer-stateless-function
class Markers extends Component {
  render() {
    return (
      <div className="divContent mt-3">
        <h2 className="headerMarkers">Инструкция</h2>
        <div className="row mt-5">
          <div className="col-xl-1 col-lg-1 col-md-2 col-sm-2 col-3">
            <img className="iconMarkers" src="./img/mark.svg" alt="" />
          </div>
          <div className="col-2"><div className="circleMarkers">1</div></div>
          <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-7">
            <p>Гуляйте по усадьбе и находите места исторической съемки. Узнавайте о сюжетах и героях фотографий.</p>
          </div>
        </div>

        <div className="row pt-2">
          <div className="col-xl-1 col-lg-1 col-md-2 col-sm-2 col-3">
            <div className="backToPast">
              Назад в
              <br />
              прошлое
            </div>
          </div>
          <div className="col-2"><div className="circleMarkers">2</div></div>
          <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-7">
            <p>Нажимайте «Назад в прошлое», чтобы совместить исторический снимок с реальностью.</p>
          </div>
        </div>

        <div className="row pt-2">
          <div className="col-xl-1 col-lg-1 col-md-2 col-sm-2 col-3">
            <div className="findAngleBlock">
              <div className="findAngleBorder">
                Найти
                <br />
                ракурс
              </div>
            </div>
          </div>
          <div className="col-2"><div className="circleMarkers">3</div></div>
          <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-7">
            <p>Подсказка «Найти ракурс» поможет понять, откуда был сделан снимок.</p>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-xl-1 col-lg-1 col-md-2 col-sm-2 col-3">
            <img className="iconMarkers" src="./img/social.png" alt="" />
          </div>
          <div className="col-2"><div className="circleMarkers">4</div></div>
          <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-7">
            <p>Снимайте, сохраняйте, делитесь.</p>
          </div>
        </div>
        <button className="nextBtn mt-3" onClick={() => window.location.href = '#/map'}>Продолжить</button>
      </div>
    );
  }
}

export default Markers;
